@tailwind base;
@tailwind components;
@tailwind utilities;
@import './player.css';

.App {
  @apply text-center h-screen w-screen overflow-hidden text-light;
}

.fullscreen.landing .project-wrap > * {
  @apply my-6;
}
.fullscreen.landing {
  @apply grid w-full h-full pb-8 lg:pb-0 grid-cols-1 grid-rows-3 lg:grid-rows-1 lg:grid-cols-3;
  /* grid-template-columns: auto; */
  /* grid-template-rows: repeat(3, 1fr);   */
}
.fullscreen.landing h2 {@apply text-xl capitalize absolute left-1/2 z-20 w-fit;
  transform: translateX(-50%);
}
.fullscreen.landing .player-wrap  {
  @apply absolute w-full top-0;
}
.fullscreen.landing .project-wrap {
  @apply relative z-10;
}
.fullscreen.landing .project-wrap a {
  @apply flex justify-center gap-2;
}
.fullscreen.landing .project-wrap > section {
  @apply absolute z-0;
}
.fullscreen.landing .video-player {
  @apply object-cover w-full absolute z-0 top-0 z-0 h-full;
  max-height: 100%;
}
.fullscreen.landing .project-wrap,
.fullscreen.landing section {
  @apply h-full;
}
.fullscreen.landing .project-wrap {
  @apply relative overflow-hidden flex flex-col justify-center gap-8;
}

.fullscreen.landing .project-wrap:nth-child(2) h2 {
  @apply text-[transparent] absolute w-full max-w-[12rem] mx-auto;
}
.fullscreen.landing .project-wrap:nth-child(2) h2:after {
  @apply absolute top-0 left-0 w-full h-12 bg-logo bg-no-repeat bg-contain;
  content: '';
}

nav {
  @apply w-full flex flex-col justify-center sticky bottom-0 z-20 items-center mx-auto;
}