@tailwind base;
@tailwind components;
@tailwind utilities;

.video-player {
    @apply cursor-pointer aspect-4/3 object-cover;
}

.video-controls {
    @apply flex z-[1] text-light absolute bottom-0 items-baseline w-full justify-start;
}

input[type="range"]::-moz-range-progress,
input[type="range"]::-ms-fill-lower {
    @apply bg-light; 
}
.rupture-icon {
    @apply absolute top-1/2 left-1/2 w-full max-w-[10rem] max-h-[10rem] z-30;
}

.video-overlay {
    @apply absolute h-screen w-screen bg-[rgba(0,0,0,0.8)] z-50 p-4;
}

.video-overlay dialog {@apply w-full mx-auto block bg-[transparent];}

  
.rupture-icon {
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
input[type="range"]::-moz-range-track,
input[type="range"]::-ms-fill-upper  {  
    background-color: transparent;
}
 
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        width: 100%;
        -webkit-appearance: none;
        background-color: transparent;
        height: 20px;
    }
    input[type='range']::-webkit-slider-runnable-track {
        height: .25em;
        -webkit-appearance: none;
        color: white;
        margin-top: -1px;
    }
    input[type='range']::-webkit-slider-thumb {
        width: 10px;
        -webkit-appearance: none;
        height: 10px;
        cursor: ew-resize;
        background: transparent;
        position: relative;
        box-shadow: -50vw 0 0 50vw white;

    }
} /* end media */ 
